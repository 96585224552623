import type Axios from "axios";
import {axiosInstance} from "../../api";
import qs from "query-string";
import type {PartCodeModel} from "../../productsearch/productApi";
import {MatchingMaintenanceGuideTimesResponse} from "../model";

class MaintenanceService {
	api: Axios;

	constructor(api: Axios) {
		this.api = api;
	}

	async getSectionFacetsByVehicle(vehicleModel: string, vehicleModelYear: string) {
		const response = await this.api.get("/maintenance/section/facets",
			{
				params: {
					vehicleModel,
					vehicleModelYear
				}
			}
		);
		return response.data;
	}

	async getCategoryIllustrations(categoryId: number) {
		const response = await this.api.get(`/maintenance/category/${categoryId}/illustrations`);
		return response.data;
	}

	async getFittingsByCategoryAndModuleNumbersAndVehicle(categoryId: number, moduleNumbers: string[], vehicleModel: string, vehicleYear: string) {
		const response = await this.api.get("/maintenance/fitting/findByCategoryAndModulesAndVehicle",
			{
				params: {
					categoryId,
					moduleReferenceNumbers: moduleNumbers,
					vehicleModelYear: vehicleYear,
					vehicleModel
				},
				paramsSerializer: params => qs.stringify(params, {arrayFormat: "repeat"})
			}
		);
		return response.data;
	}

	async getFittingsByPartCodeAndVehicle(productNumber: string, partCode: PartCodeModel, vehicleModel: string, vehicleYear: string) {
		const response = await this.api.get("/maintenance/fitting/findByPartCodeAndVehicle",
			{
				params: {
					partCode: partCode.partCode,
					partCodeSymbol: partCode.partCodeSymbol,
					vehicleModelYear: vehicleYear,
					vehicleModel
				}
			}
		);
		return response.data;
	}

	async getMaintenanceBundles() {
		const response = await this.api.get("/maintenance/bundle");
		return response.data;
	}

	async getFittingsByOperationsAndVehicle(operationIds: number[], vehicleModel: string, vehicleYear: string): Promise<MatchingMaintenanceGuideTimesResponse> {
		const response = await this.api.get("/maintenance/fitting/findByOperationAndVehicle",
			{
				params: {
					operationIds,
					vehicleModelYear: vehicleYear,
					vehicleModel
				},
				paramsSerializer: params => qs.stringify(params, {arrayFormat: "repeat"})
			}
		);
		return response.data;
	}
}

const MaintenanceSectionServiceInstance = new MaintenanceService(axiosInstance);
export default MaintenanceSectionServiceInstance;