import proposalService from "../service/proposalService";
import {push} from "connected-react-router";
import type {Dispatch, GetState, ThunkAction} from "../../actions";
import type {CreateProposalFormState} from "../component/CreateProposalForm";
import WatchListConsumer from "../service/watchListConsumer";
import type {
	ConsumablePositionType,
	PartAmounts,
	Proposal,
	ProposalFilter,
	SparePositionType,
	TaskPositionType
} from "../model";
import {AddendumType} from "../model";
import i18n from "../../i18n/i18n";
import inspectionService from "../../service/inspectionService";
import ProductService from "../../service/productService";
import RouteDictionary from "../../RouteDictionary";

export const CREATE_PROPOSAL = {
	REQUEST: "CREATE_PROPOSAL_REQUEST",
	SUCCESS: "CREATE_PROPOSAL_SUCCESS",
	FAILURE: "CREATE_PROPOSAL_FAILURE",
};
export const FETCH_PROPOSALS = {
	REQUEST: "FETCH_PROPOSALS_REQUEST",
	SUCCESS: "FETCH_PROPOSALS_SUCCESS",
	FAILURE: "FETCH_PROPOSALS_FAILURE",
};
export const SET_PROPOSAL_FILTER = "SET_PROPOSAL_FILTER";
export const RESET_PROPOSAL_FILTER = "RESET_PROPOSAL_FILTER";
export const PROPOSALS_SET_PAGE_SIZE = "PROPOSALS_SET_PAGE_SIZE";
export const SET_DATE_SPAN = "SET_DATE_SPAN";
export const SEARCH_PROPOSALS = {
	REQUEST: "SEARCH_PROPOSALS_REQUEST",
	SUCCESS: "SEARCH_PROPOSALS_SUCCESS",
	FAILURE: "SEARCH_PROPOSALS_FAILURE",
};
export const PROPOSALS_SET_PAGE = "PROPOSALS_SET_PAGE";
export const OPEN_PROPOSALS = "OPEN_PROPOSALS";

export const FETCH_PROPOSAL = {
	REQUEST: "FETCH_PROPOSAL_REQUEST",
	SUCCESS: "FETCH_PROPOSAL_SUCCESS",
	FAILURE: "FETCH_PROPOSAL_FAILURE",
};
export const FINISH_PROPOSAL = {
	REQUEST: "FINISH_PROPOSAL_REQUEST",
	SUCCESS: "FINISH_PROPOSAL_SUCCESS",
	FAILURE: "FINISH_PROPOSAL_FAILURE",
};
export const DELETE_PROPOSAL = {
	REQUEST: "DELETE_PROPOSAL_REQUEST",
	SUCCESS: "DELETE_PROPOSAL_SUCCESS",
	FAILURE: "DELETE_PROPOSAL_FAILURE",
};
export const COPY_PROPOSAL = {
	REQUEST: "COPY_PROPOSAL_REQUEST",
	SUCCESS: "COPY_PROPOSAL_SUCCESS",
	FAILURE: "COPY_PROPOSAL_FAILURE",
};
export const PRINT_PROPOSAL = {
	REQUEST: "PRINT_PROPOSAL_REQUEST",
	SUCCESS: "PRINT_PROPOSAL_SUCCESS",
	FAILURE: "PRINT_PROPOSAL_FAILURE",
};
export const UPDATE_PROPOSAL = {
	REQUEST: "UPDATE_PROPOSAL_REQUEST",
	SUCCESS: "UPDATE_PROPOSAL_SUCCESS",
	FAILURE: "UPDATE_PROPOSAL_FAILURE",
};
export const ADD_TASK_POSITION = {
	REQUEST: "ADD_TASK_POSITION_REQUEST",
	SUCCESS: "ADD_TASK_POSITION_SUCCESS",
	FAILURE: "ADD_TASK_POSITION_FAILURE",
};
export const REMOVE_TASK_POSITION = {
	REQUEST: "REMOVE_TASK_POSITION_REQUEST",
	SUCCESS: "REMOVE_TASK_POSITION_SUCCESS",
	FAILURE: "REMOVE_TASK_POSITION_FAILURE",
};
export const ADD_CONSUMABLE_POSITION = {
	REQUEST: "ADD_CONSUMABLE_POSITION_REQUEST",
	SUCCESS: "ADD_CONSUMABLE_POSITION_SUCCESS",
	FAILURE: "ADD_CONSUMABLE_POSITION_FAILURE",
};
export const ADD_SPARE_POSITIONS = {
	REQUEST: "ADD_SPARE_POSITIONS_REQUEST",
	SUCCESS: "ADD_SPARE_POSITIONS_SUCCESS",
	FAILURE: "ADD_SPARE_POSITIONS_FAILURE",
};
export const UPDATE_CONSUMABLE_POSITION = {
	REQUEST: "UPDATE_CONSUMABLE_POSITION_REQUEST",
	SUCCESS: "UPDATE_CONSUMABLE_POSITION_SUCCESS",
	FAILURE: "UPDATE_CONSUMABLE_POSITION_FAILURE",
};
export const UPDATE_TASK_POSITION = {
	REQUEST: "UPDATE_TASK_POSITION_REQUEST",
	SUCCESS: "UPDATE_TASK_POSITION_SUCCESS",
	FAILURE: "UPDATE_TASK_POSITION_FAILURE",
};
export const REMOVE_CONSUMABLE_POSITION = {
	REQUEST: "REMOVE_CONSUMABLE_POSITION_REQUEST",
	SUCCESS: "REMOVE_CONSUMABLE_POSITION_SUCCESS",
	FAILURE: "REMOVE_CONSUMABLE_POSITION_FAILURE",
};
export const FETCH_SPARE_PART_DETAILS = {
	REQUEST: "FETCH_SPARE_PART_DETAILS_REQUEST",
	SUCCESS: "FETCH_SPARE_PART_DETAILS_SUCCESS",
	FAILURE: "FETCH_SPARE_PART_DETAILS_FAILURE",
};
export const UPDATE_SPARE_POSITION = {
	REQUEST: "UPDATE_SPARE_POSITION_REQUEST",
	SUCCESS: "UPDATE_SPARE_POSITION_SUCCESS",
	FAILURE: "UPDATE_SPARE_POSITION_FAILURE",
};
export const REMOVE_SPARE_POSITION = {
	REQUEST: "REMOVE_SPARE_POSITION_REQUEST",
	SUCCESS: "REMOVE_SPARE_POSITION_SUCCESS",
	FAILURE: "REMOVE_SPARE_POSITION_FAILURE",
};
export const CONSUME_WATCHLIST = {
	REQUEST: "CONSUME_WATCHLIST_REQUEST",
	SUCCESS: "CONSUME_WATCHLIST_SUCCESS",
	FAILURE: "CONSUME_WATCHLIST_FAILURE",
};
export const EDIT_DISCOUNTS = {
	REQUEST: "EDIT_DISCOUNTS_REQUEST",
	SUCCESS: "EDIT_DISCOUNTS_SUCCESS",
	FAILURE: "EDIT_DISCOUNTS_FAILURE",
};
export const UPDATE_PROPOSAL_VALIDITY = {
	REQUEST: "UPDATE_VALIDITY_REQUEST",
	SUCCESS: "UPDATE_VALIDITY_SUCCESS",
	FAILURE: "UPDATE_VALIDITY_FAILURE",
};

export const UPDATE_CURRENT_PROPOSAL = "UPDATE_CURRENT_PROPOSAL";
export const GET_INSPECTION_ELEMENTS = {
	REQUEST: "GET_INSPECTION_ELEMENTS_REQUEST",
	SUCCESS: "GET_INSPECTION_ELEMENTS_SUCCESS",
	FAILURE: "GET_INSPECTION_ELEMENTS_FAILURE",
	CLEANUP: "GET_INSPECTION_ELEMENTS_CLEANUP",
};

export const ACCEPT_PROPOSAL = {
	REQUEST: "ACCEPT_PROPOSAL_REQUEST",
	SUCCESS: "ACCEPT_PROPOSAL_SUCCESS",
	FAILURE: "ACCEPT_PROPOSAL_FAILURE",
};

export const REJECT_PROPOSAL = {
	REQUEST: "REJECT_PROPOSAL_REQUEST",
	SUCCESS: "REJECT_PROPOSAL_SUCCESS",
	FAILURE: "REJECT_PROPOSAL_FAILURE",
};

export const SET_CREATE_PROPOSAL_ADDENDUM = "SET_CREATE_PROPOSAL_ADDENDUM";
export const SET_IMPORT_MAINTENANCE_LIST_ADDENDUM = "SET_IMPORT_MAINTENANCE_LIST_ADDENDUM";
export const SET_ADD_CONSUMABLE_ADDENDUM = "SET_ADD_CONSUMABLE_ADDENDUM";
export const SET_ADD_PRODUCT_ADDENDUM = "SET_ADD_PRODUCT_ADDENDUM";
export const SET_EDIT_CUSTOMER_ADDENDUM = "SET_EDIT_CUSTOMER_ADDENDUM";
export const SET_PROPOSAL_CONFIRMATION_ADDENDUM = "SET_PROPOSAL_CONFIRMATION_ADDENDUM";
export const SET_ADD_TASK_ADDENDUM = "SET_ADD_TASK_ADDENDUM";
export const SET_ADD_SPARE_TASK_ADDENDUM = "SET_ADD_SPARE_TASK_ADDENDUM";
export const SET_PROPOSAL_PRINT_ADDENDUM = "SET_PROPOSAL_PRINT_ADDENDUM";

export const getInspectionElements = (id: number, vin: string) => {
	return async dispatch => {
		dispatch({type: GET_INSPECTION_ELEMENTS.REQUEST});
		try {
			const inspectionElements = await inspectionService.getInspectionElements(id, vin);
			dispatch({type: GET_INSPECTION_ELEMENTS.SUCCESS, payLoad: inspectionElements});
		} catch (e) {
			dispatch({type: GET_INSPECTION_ELEMENTS.FAILURE, payLoad: e});
		}
	};
};

export const importInspection = (parts: PartAmounts[], consumables: ConsumablePositionType[], task: TaskPositionType) => {
	return async (dispatch, getState) => {
		const {aggregateId} = getState().proposal.current;
		const proposal = await proposalService
			.addTask(aggregateId, task)
			.then(() =>
				Object.values(consumables)
					.map(consumable =>
						proposalService.addConsumable(aggregateId, {
							...consumable,
							unitPrice: getState().profile.consumablePrices.find(
								consumablePrice => {
									return consumablePrice.consumable.name === consumable.name;
								}
							).price
						})
					)
			)
			.then(() => WatchListConsumer.consumeParts(aggregateId, parts));
		dispatch(updateCurrentProposal(proposal));

	};
};

export const createProposal = (createProposalForm: CreateProposalFormState) => {
	return async dispatch => {
		dispatch({type: CREATE_PROPOSAL.REQUEST});
		try {
			const proposal = await proposalService.createProposal(createProposalForm);
			dispatch({
				type: CREATE_PROPOSAL.SUCCESS,
				payload: proposal
			});
			dispatch(push(`${RouteDictionary.getRoutes().PROPOSALS}/${proposal.aggregateId}`));
		} catch (e) {
			dispatch({type: CREATE_PROPOSAL.FAILURE});
			console.error(e);
		}
	};
};

export const editProposalCustomer = (customerData: CreateProposalFormState) => {
	return async (dispatch, getState) => {
		const {aggregateId} = getState().proposal.current;
		dispatch({type: UPDATE_PROPOSAL.REQUEST});
		try {
			const proposal = await proposalService.editCustomer(customerData, aggregateId);
			dispatch({
				type: UPDATE_PROPOSAL.SUCCESS,
				payload: proposal
			});
		} catch (e) {
			dispatch({type: UPDATE_PROPOSAL.FAILURE});
			console.error(e);
		}
	};
};

export const fetchProposals = () => {
	return async dispatch => {
		dispatch({type: FETCH_PROPOSALS.REQUEST});
		try {
			const proposals = await proposalService.fetchProposals();
			dispatch({
				type: FETCH_PROPOSALS.SUCCESS,
				payload: proposals
			});
		} catch (e) {
			dispatch({type: FETCH_PROPOSALS.FAILURE});
			console.error(e);
		}
	};
};

export const setProposalFilter = (proposalFilter: ProposalFilter) => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: SET_PROPOSAL_FILTER,
			payload: proposalFilter
		});
	};
};

export const resetProposalFilter = () => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: RESET_PROPOSAL_FILTER
		});
	};
};

export const setDateSpan = (dateSpan: number) => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: SET_DATE_SPAN,
			payload: dateSpan
		});
	};
};

export function setPageSize(pageSize) {
	return dispatch => {
		dispatch({
			type: PROPOSALS_SET_PAGE_SIZE,
			pageSize: pageSize
		});
		dispatch(searchProposals());
	};
}

export const searchProposals = () => {
	return async (dispatch: Dispatch, getState: GetState) => {
		const pagination = getState().proposal.pagination;
		const filterParameter: ProposalFilter = {
			...getState().proposal.filterParameter
		};
		dispatch({type: SEARCH_PROPOSALS.REQUEST});
		try {
			const proposals = await proposalService.searchProposal(filterParameter, pagination);
			dispatch({
				type: SEARCH_PROPOSALS.SUCCESS,
				payload: proposals
			});
		} catch (e) {
			dispatch({type: SEARCH_PROPOSALS.FAILURE});
			console.error(e);
		}
	};
};

export function loadNextPage() {
	return (dispatch, getState) => {
		const {page, totalPages} = getState().proposal.pagination;
		dispatch({
			type: PROPOSALS_SET_PAGE,
			page: (page + 1 < totalPages) ? page + 1 : page
		});
		dispatch(searchProposals());
	};
}

export function loadPrevPage() {
	return (dispatch, getState) => {
		const {page} = getState().proposal.pagination;
		dispatch({
			type: PROPOSALS_SET_PAGE,
			page: (page > 0) ? page - 1 : page
		});
		dispatch(searchProposals());
	};
}

export function setPage(page) {
	return dispatch => {
		dispatch({
			type: PROPOSALS_SET_PAGE,
			page
		});
	};
}

export const openProposals = () => {
	return dispatch => {
		dispatch({type: OPEN_PROPOSALS});
	};
};

export const fetchProposal = (id: string) => {
	return async dispatch => {
		dispatch(
			{
				type: FETCH_PROPOSAL.REQUEST,
				payload: id,
				retry: true
			});
	};
};

export const finishProposal = (id: string) => {
	return async dispatch => {
		dispatch({type: FINISH_PROPOSAL.REQUEST});
		try {
			const proposal = await proposalService.finishProposal(id);
			dispatch({
				type: FINISH_PROPOSAL.SUCCESS,
				payload: proposal
			});
		} catch (e) {
			dispatch({
				type: FINISH_PROPOSAL.FAILURE,
				errorMessage: i18n.t("proposal.actions.finish.failure")
			});
			console.error(e);
		}
	};
};

export const deleteProposal = (id: string) => {
	return async dispatch => {
		dispatch({type: DELETE_PROPOSAL.REQUEST});
		try {
			await proposalService.deleteProposal(id);
			dispatch({
				type: DELETE_PROPOSAL.SUCCESS,
				payload: id
			});
		} catch (e) {
			dispatch({type: DELETE_PROPOSAL.FAILURE});
			console.error(e);
		}
	};
};

export const copyProposal = (id: string) => {
	return async dispatch => {
		dispatch({type: COPY_PROPOSAL.REQUEST});
		try {
			const proposal = await proposalService.copyProposal(id);
			dispatch({
				type: COPY_PROPOSAL.SUCCESS,
				payload: proposal.aggregateId
			});
			dispatch(push(`${RouteDictionary.getRoutes().PROPOSALS}/${proposal.aggregateId}`));
		} catch (e) {
			dispatch({type: COPY_PROPOSAL.FAILURE});
			console.error(e);
		}
	};
};

const updateCurrentProposal = (proposal: Proposal) => {
	return async dispatch => {
		return dispatch({
			type: UPDATE_CURRENT_PROPOSAL,
			payload: proposal
		});
	};
};

export const acceptProposal = (id: string) => {
	return async dispatch => {
		dispatch({type: ACCEPT_PROPOSAL.REQUEST});
		try {
			const proposal = await proposalService.acceptProposal(id);
			dispatch({
				type: ACCEPT_PROPOSAL.SUCCESS,
				payload: proposal
			});
		} catch (e) {
			dispatch({type: ACCEPT_PROPOSAL.FAILURE});
			console.error(e);
		}
	};
};

export const rejectProposal = (id: string) => {
	return async dispatch => {
		dispatch({type: REJECT_PROPOSAL.REQUEST});
		try {
			const proposal = await proposalService.rejectProposal(id);
			dispatch({
				type: REJECT_PROPOSAL.SUCCESS,
				payload: proposal
			});
		} catch (e) {
			dispatch({type: REJECT_PROPOSAL.FAILURE});
			console.error(e);
		}
	};
};

export const addTaskForCurrentProposal = (task: TaskPositionType) => {
	return async (dispatch, getState) => {
		const {aggregateId} = getState().proposal.current;
		dispatch({type: ADD_TASK_POSITION.REQUEST});
		try {
			const proposal = await proposalService.addTask(aggregateId, task);
			dispatch({
				type: ADD_TASK_POSITION.SUCCESS,
				count: 1
			});
			dispatch(updateCurrentProposal(proposal));
		} catch (e) {
			dispatch({type: ADD_TASK_POSITION.FAILURE});
			console.error(e);
		}
	};
};

export const addTasksToCurrentProposal = (tasks: TaskPositionType[]) => {
	return async (dispatch, getState) => {
		const {aggregateId} = getState().proposal.current;
		dispatch({type: ADD_TASK_POSITION.REQUEST});
		try {
			const proposal = await proposalService.addTasks(aggregateId, tasks);
			dispatch({
				type: ADD_TASK_POSITION.SUCCESS,
				count: tasks.length
			});
			dispatch(updateCurrentProposal(proposal));
		} catch (e) {
			dispatch({type: ADD_TASK_POSITION.FAILURE});
			console.error(e);
		}
	};
};

export const addSparesForProposal = (sparePositions: SparePositionType[]) => {
	return async (dispatch, getState) => {
		dispatch({type: ADD_SPARE_POSITIONS.REQUEST});
		const {aggregateId} = getState().proposal.current;

		try {
			const proposal = await proposalService.addSpares(aggregateId, sparePositions);
			dispatch({
				type: ADD_SPARE_POSITIONS.SUCCESS,
				count: sparePositions.length
			});
			dispatch(updateCurrentProposal(proposal));
		} catch (e) {
			dispatch({type: ADD_SPARE_POSITIONS.FAILURE});
			console.error(e);
		}
	};
};

export const addConsumableForProposal = (consumable: ConsumablePositionType) => {
	return async (dispatch, getState) => {
		dispatch({type: ADD_CONSUMABLE_POSITION.REQUEST});
		const {aggregateId} = getState().proposal.current;
		try {
			const proposal = await proposalService.addConsumable(aggregateId, consumable);
			dispatch(updateCurrentProposal(proposal));
		} catch (e) {
			dispatch({type: ADD_CONSUMABLE_POSITION.FAILURE});
			console.error(e);
		}
	};
};

export const updateConsumableForProposal = (consumable: ConsumablePositionType) => {
	return async (dispatch, getState) => {
		const {aggregateId} = getState().proposal.current;
		dispatch({type: UPDATE_CONSUMABLE_POSITION.REQUEST});
		try {
			const proposal = await proposalService.updateConsumable(aggregateId, consumable);
			dispatch(updateCurrentProposal(proposal));
		} catch (e) {
			dispatch({type: UPDATE_CONSUMABLE_POSITION.FAILURE});
			console.error(e);
		}
	};
};

export const updateTaskForProposal = (task: TaskPositionType) => {
	return async (dispatch, getState) => {
		const {aggregateId} = getState().proposal.current;
		dispatch({type: UPDATE_TASK_POSITION.REQUEST});
		try {
			const proposal = await proposalService.updateTask(aggregateId, task);
			dispatch(updateCurrentProposal(proposal));
		} catch (e) {
			dispatch({type: UPDATE_TASK_POSITION.FAILURE});
			console.error(e);
		}
	};
};

export const updateValidityForCurrentProposal = (validFrom: Date, validUntil: Date) => {
	return async (dispatch, getState) => {
		const {aggregateId} = getState().proposal.current;
		dispatch({type: UPDATE_PROPOSAL_VALIDITY.REQUEST});
		try {
			const proposal = await proposalService.updateValidityForProposal(aggregateId, validFrom, validUntil);
			dispatch(updateCurrentProposal(proposal));
		} catch (e) {
			dispatch({type: UPDATE_PROPOSAL_VALIDITY.FAILURE});
			console.error(e);
		}
	};
};
export const removeConsumableForProposal = (name: string) => {
	return async (dispatch, getState) => {
		const {aggregateId} = getState().proposal.current;
		dispatch({type: REMOVE_CONSUMABLE_POSITION.REQUEST});
		try {
			const proposal = await proposalService.removeConsumable(aggregateId, name);
			dispatch(updateCurrentProposal(proposal));
		} catch (e) {
			dispatch({type: REMOVE_CONSUMABLE_POSITION.FAILURE});
			console.error(e);
		}
	};
};

export const cleanUpInspectionElements = () => {
	return dispatch => {
		dispatch(
			{
				type: GET_INSPECTION_ELEMENTS.CLEANUP
			});
	};
};

export const removeTaskForProposal = (taskId: number) => {
	return async (dispatch, getState) => {
		const {aggregateId} = getState().proposal.current;
		dispatch({type: REMOVE_TASK_POSITION.REQUEST});
		try {
			const proposal = await proposalService.removeTask(aggregateId, taskId);
			dispatch(updateCurrentProposal(proposal));
		} catch (e) {
			dispatch({type: REMOVE_TASK_POSITION.FAILURE});
			console.error(e);
		}
	};
};

export const removeSpareFromCurrentProposal = (productNumber: string) => {
	return async (dispatch, getState) => {
		const {aggregateId} = getState().proposal.current;
		dispatch({type: REMOVE_SPARE_POSITION.REQUEST});
		try {
			const proposal = await proposalService.removeSpare(aggregateId, productNumber);
			dispatch(updateCurrentProposal(proposal));
		} catch (e) {
			dispatch({type: REMOVE_SPARE_POSITION.FAILURE});
			console.error(e);
		}
	};
};

export function updateSparePosition(sparePosition: SparePositionType) {
	return async (dispatch: Dispatch, getState: GetState) => {
		const {aggregateId} = getState().proposal.current;
		dispatch({type: UPDATE_SPARE_POSITION.REQUEST});
		try {
			const proposal = await proposalService.updateSparePosition(aggregateId, sparePosition);
			dispatch(updateCurrentProposal(proposal));
		} catch (e) {
			dispatch({type: UPDATE_SPARE_POSITION.FAILURE});
			console.error(e);
		}
	};
}

export function consumeWatchList(watchListName: string): ThunkAction {
	return async (dispatch, getState) => {
		const proposalId = getState().proposal.current.aggregateId;
		dispatch({type: CONSUME_WATCHLIST.REQUEST});
		try {
			const proposal = await WatchListConsumer.consumeWatchList(proposalId, watchListName);
			dispatch({
				type: CONSUME_WATCHLIST.SUCCESS,
				payload: proposal
			});
		} catch (e) {
			dispatch({type: CONSUME_WATCHLIST.FAILURE});
		}
	};
}

export function updateDiscountsForCurrentProposal(sparePositionsDiscount: number, taskPositionsDiscount: number, consumablePositionsDiscount: number): ThunkAction {
	return async (dispatch, getState) => {
		const {aggregateId} = getState().proposal.current;
		dispatch({type: EDIT_DISCOUNTS.REQUEST});
		try {
			const proposal = await proposalService.editDiscounts(
				{
					consumablePositionsDiscount: {value: consumablePositionsDiscount},
					sparePositionsDiscount: {value: sparePositionsDiscount},
					taskPositionsDiscount: {value: taskPositionsDiscount},
				},
				aggregateId
			);
			dispatch(updateCurrentProposal(proposal));
		} catch (e) {
			dispatch({type: EDIT_DISCOUNTS.FAILURE});
		}
	};
}

export function getProductDetailsByProductNumber(productNumber: string) {
	return async dispatch => {
		dispatch({type: FETCH_SPARE_PART_DETAILS.REQUEST});
		try {
			const product = await ProductService.getProduct(productNumber, false);
			dispatch({
				type: FETCH_SPARE_PART_DETAILS.SUCCESS,
				payload: {...product}
			});
		} catch (e) {
			dispatch({type: FETCH_SPARE_PART_DETAILS.FAILURE});
			console.error(e);
		}
	};
}

export const setProposalAddendum = (addendumType: AddendumType, payload: boolean) => {
	switch (addendumType) {
		case (AddendumType.CREATE_PROPOSAL):
			return {
				type: SET_CREATE_PROPOSAL_ADDENDUM,
				payload
			};
		case (AddendumType.IMPORT_MAINTENANCE_LIST):
			return {
				type: SET_IMPORT_MAINTENANCE_LIST_ADDENDUM,
				payload
			};
		case (AddendumType.ADD_CONSUMABLE):
			return {
				type: SET_ADD_CONSUMABLE_ADDENDUM,
				payload
			};
		case (AddendumType.ADD_PRODUCT):
			return {
				type: SET_ADD_PRODUCT_ADDENDUM,
				payload
			};
		case (AddendumType.EDIT_COSTUMER):
			return {
				type: SET_EDIT_CUSTOMER_ADDENDUM,
				payload
			};
		case (AddendumType.ADD_TASK):
			return {
				type: SET_ADD_TASK_ADDENDUM,
				payload
			};
		case (AddendumType.CONFIRM_PROPOSAL):
			return {
				type: SET_PROPOSAL_CONFIRMATION_ADDENDUM,
				payload
			};
		case (AddendumType.PRINT_PROPOSAL):
			return {
				type: SET_PROPOSAL_PRINT_ADDENDUM,
				payload
			};
		case (AddendumType.ADD_SPARE_PART_TASK):
			return {
				type: SET_ADD_SPARE_TASK_ADDENDUM,
				payload
			};
		default:
			return null;
	}
};

export const createProposalAndAddTasksWithSpares = (createProposalForm: CreateProposalFormState, tasks: TaskPositionType[], sparePositions: SparePositionType[]) => {
	return async dispatch => {
		dispatch({ type: CREATE_PROPOSAL.REQUEST });
		try {
			const proposal = await proposalService.createProposal(createProposalForm);
			dispatch({
				type: CREATE_PROPOSAL.SUCCESS,
				payload: proposal
			});

			dispatch({ type: ADD_TASK_POSITION.REQUEST });
			try {
				const updatedProposalWithTasks = await proposalService.addTasks(proposal.aggregateId, tasks);
				dispatch({
					type: ADD_TASK_POSITION.SUCCESS,
					count: tasks.length
				});
				dispatch(updateCurrentProposal(updatedProposalWithTasks));

				dispatch({ type: ADD_SPARE_POSITIONS.REQUEST });
				try {
					const updatedProposalWithSpares = await proposalService.addSpares(proposal.aggregateId, sparePositions);
					dispatch({
						type: ADD_SPARE_POSITIONS.SUCCESS,
						count: sparePositions.length
					});
					dispatch(updateCurrentProposal(updatedProposalWithSpares));
				} catch (e) {
					dispatch({ type: ADD_SPARE_POSITIONS.FAILURE });
					console.error(e);
				}
			} catch (e) {
				dispatch({ type: ADD_TASK_POSITION.FAILURE });
				console.error(e);
			}

			dispatch(push(`${RouteDictionary.getRoutes().PROPOSALS}/${proposal.aggregateId}`));
		} catch (e) {
			dispatch({ type: CREATE_PROPOSAL.FAILURE });
			console.error(e);
		}
	};
};
