import maintenanceService from "../service/maintenanceService";
import type {MaintenanceGuideTimeModel} from "../model";
import type {Dispatch, GetState} from "../../actions";
import type {PartCodeModel, ProductResponse} from "../../productsearch/productApi";
import productApi from "../../productsearch/productApi";
import type {VkCode} from "../../vehicle/model";
import {MaintenanceTaskSearchContext} from "../model";

export const SET_SELECTED_MAINTENANCE_CATEGORY_IN_PROPOSAL = "SET_SELECTED_MAINTENANCE_CATEGORY_IN_PROPOSAL";
export const SET_MAINTENANCE_GUIDE_TIME_LAST_VEHICLE_CONTEXT_IN_PROPOSAL = "SET_MAINTENANCE_GUIDE_TIME_LAST_VEHICLE_CONTEXT_IN_PROPOSAL";
export const RESET_SELECTED_MAINTENANCE_CATEGORY_IN_PROPOSAL = "RESET_SELECTED_MAINTENANCE_CATEGORY_IN_PROPOSAL";
export const RESET_MAINTENANCE_GUIDE_TIME_SEARCH_IN_PROPOSAL = "RESET_MAINTENANCE_GUIDE_TIME_SEARCH_IN_PROPOSAL";
export const SET_MAINTENANCE_GUIDE_TIME_FILTER_IN_PROPOSAL = "SET_MAINTENANCE_GUIDE_TIME_FILTER_IN_PROPOSAL";
export const RESET_MAINTENANCE_TASK_SPARES_IN_PROPOSAL = "RESET_MAINTENANCE_TASK_SPARES_IN_PROPOSAL";
export const RESET_MATCHING_GUIDE_TIMES_IN_PROPOSAL = "RESET_MATCHING_GUIDE_TIMES_IN_PROPOSAL";
export const SET_SPARE_PART_MAINTENANCE_GUIDE_TIME_IN_PROPOSAL = "SET_SPARE_PART_MAINTENANCE_GUIDE_TIME_IN_PROPOSAL";
export const SET_MAINTENANCE_TASK_SEARCH_CONTEXT = "SET_MAINTENANCE_TASK_SEARCH_CONTEXT";

export const FETCH_MAINTENANCE_SECTIONS_FACETS_IN_PROPOSAL = {
	REQUEST: "FETCH_MAINTENANCE_SECTIONS_IN_PROPOSAL_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_SECTIONS_IN_PROPOSAL_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_SECTIONS_IN_PROPOSAL_FAILURE",
};

export const FETCH_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL = {
	REQUEST: "FETCH_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL_FAILURE",
};

export const FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL = {
	REQUEST: "FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL_REQUEST",
	SUCCESS: "FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL_SUCCESS",
	FAILURE: "FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL_FAILURE",
};

export const FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS_IN_PROPOSAL = {
	REQUEST: "FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS_IN_PROPOSAL_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS_IN_PROPOSAL_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS_IN_PROPOSAL_FAILURE",
};

export const FETCH_MAINTENANCE_TASK_SPARES_IN_PROPOSAL = {
	REQUEST: "FETCH_MAINTENANCE_TASK_SPARES_IN_PROPOSAL_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_TASK_SPARES_IN_PROPOSAL_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_TASK_SPARES_IN_PROPOSAL_FAILURE",
};

export const setSelectedMaintenanceCategory = (categoryId: number) => {
	return dispatch => {
		dispatch(
			{
				type: SET_SELECTED_MAINTENANCE_CATEGORY_IN_PROPOSAL,
				payload: categoryId
			});
	};
};

export const resetSelectedMaintenanceCategory = () => {
	return dispatch => {
		dispatch({type: RESET_SELECTED_MAINTENANCE_CATEGORY_IN_PROPOSAL});
	};
};

export const resetMaintenanceGuideTimeSearch = () => {
	return dispatch => {
		dispatch({type: RESET_MAINTENANCE_GUIDE_TIME_SEARCH_IN_PROPOSAL});
	};
};

export const setMaintenanceGuideTimeFilter = (isActive: boolean) => {
	return dispatch => {
		dispatch(
			{
				type: SET_MAINTENANCE_GUIDE_TIME_FILTER_IN_PROPOSAL,
				payload: isActive
			});
	};
};

export const setVehicleSearchContext = (vkCode: VkCode) => {
	return async dispatch => {
		const {modelYear, type} = vkCode;
		dispatch({
			type: SET_MAINTENANCE_GUIDE_TIME_LAST_VEHICLE_CONTEXT_IN_PROPOSAL,
			payload: {modelYear, type}
		});
	};
};

export const getMaintenanceFittingsByCategoryAndModuleNumbersAndVehicle = (categoryId: number, moduleNumbers: string[]) => {
	return async (dispatch, getState) => {
		const {modelYear, type} = getState().proposal.vehicleDetails.vkCode;
		dispatch({type: FETCH_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL.REQUEST});
		try {
			const matchingGuideTimes = await maintenanceService
				.getFittingsByCategoryAndModuleNumbersAndVehicle(
					categoryId,
					moduleNumbers,
					type,
					modelYear
				);
			dispatch({
				type: FETCH_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL.SUCCESS,
				payload: matchingGuideTimes
			});
		} catch (e) {
			dispatch({type: FETCH_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL.FAILURE});
			console.error(e);
		}
	};
};

export const getMaintenanceGuideTimeModelsByPartCodeAndVehicle = (partCode: PartCodeModel, productNumber: number) => {
	return async (dispatch, getState) => {
		const {modelYear, type} = getState().proposal.vehicleDetails.vkCode;
		dispatch({type: FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL.REQUEST});
		try {
			const sparePartMaintenanceGuideTimes = await maintenanceService
				.getFittingsByPartCodeAndVehicle(
					productNumber,
					partCode,
					type,
					modelYear
				);
			dispatch({
				type: FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL.SUCCESS,
				payload: {
					productNumber,
					sparePartMaintenanceGuideTimes
				}
			});
		} catch (e) {
			dispatch({type: FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS_IN_PROPOSAL.FAILURE});
			console.error(e);
		}
	};
};

export function fetchMaintenanceSectionFacetsByVehicle() {
	return async (dispatch, getState) => {
		const {modelYear, type} = getState().proposal.vehicleDetails.vkCode;
		dispatch({type: FETCH_MAINTENANCE_SECTIONS_FACETS_IN_PROPOSAL.REQUEST});
		try {
			const sectionFacets = await maintenanceService.getSectionFacetsByVehicle(type, modelYear);
			dispatch({
				type: FETCH_MAINTENANCE_SECTIONS_FACETS_IN_PROPOSAL.SUCCESS,
				payload: sectionFacets
			});
		} catch (e) {
			dispatch({type: FETCH_MAINTENANCE_SECTIONS_FACETS_IN_PROPOSAL.FAILURE});
			console.error(e);
		}
	};
}

export function fetchMaintenanceCategoryIllustrations(categoryId: number) {
	return async dispatch => {
		dispatch({type: FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS_IN_PROPOSAL.REQUEST});
		try {
			const illustrations = await maintenanceService.getCategoryIllustrations(categoryId);
			dispatch({
				type: FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS_IN_PROPOSAL.SUCCESS,
				payload: illustrations
			});
		} catch (e) {
			dispatch({type: FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS_IN_PROPOSAL.FAILURE});
			console.error(e);
		}
	};
}

export const resetMaintenanceTaskSpares = () => {
	return dispatch => {
		dispatch(
			{type: RESET_MAINTENANCE_TASK_SPARES_IN_PROPOSAL});
	};
};

export const resetMatchingGuideTimes = () => {
	return dispatch => {
		dispatch(
			{type: RESET_MATCHING_GUIDE_TIMES_IN_PROPOSAL});
	};
};

export function fetchProductDetailsForMaintenanceGuideTimes(guideTimeModels: MaintenanceGuideTimeModel[], withExternalStocks: boolean) {
	return async (dispatch: Dispatch, getState: GetState) => {
		const factoryCode = getState().proposal.vehicleDetails.factoryCode;
		const destinationCode = getState().proposal.vehicleDetails.destinationCode;
		const modelYearCode = getState().proposal.vehicleDetails.modelYearRestriction;
		const optionCodes = getState().proposal.vehicleDetails.optionCodes;
		const colorCode = getState().proposal.vehicleDetails.colorCode;
		const interiorColorCode = getState().proposal.vehicleDetails.interiorColorCode;
		const vehicleBuildDate = getState().proposal.vehicleDetails.productionDate;
		const vehicleDecommissionDate = getState().proposal.vehicleDetails.decommissionDate;

		dispatch({ type: FETCH_MAINTENANCE_TASK_SPARES_IN_PROPOSAL.REQUEST });

		try {
			const allResponses = [];

			for (const guideTimeModel of guideTimeModels) {
				const productResponse: ProductResponse = await productApi.getByPartCodeAndMostRelevantPrecisionDetails(
					guideTimeModel.partCodes,
					factoryCode,
					destinationCode,
					modelYearCode,
					optionCodes,
					colorCode,
					interiorColorCode,
					null,
					vehicleBuildDate,
					vehicleDecommissionDate
				);

				let productDetails = [];
				if (!!productResponse.content.length) {
					const partNumbers = productResponse.content.map(p => p.partNo);
					productDetails = await productApi.getProductDetails(withExternalStocks, partNumbers);
				}

				const productsWithDetails = productResponse.content.map(product => {
					const details = productDetails.find(details => product.partNo === details.partNumber);
					return {
						productNumber: product.partNo,
						name: product.name,
						taskDescription: guideTimeModel.taskDescription + " " + guideTimeModel.operationActionDescription,
						retailPrice: details.storage.retailPrice,
						unit: "CHUNK",
						specification: product.specification,
						comment: product.comment,
						amount: product.quantity
					};
				});

				allResponses.push({
					operationNumber: guideTimeModel.operationNumber,
					description: guideTimeModel.taskDescription + " " + guideTimeModel.operationActionDescription,
					spares: productsWithDetails
				});
			}

			dispatch({
				type: FETCH_MAINTENANCE_TASK_SPARES_IN_PROPOSAL.SUCCESS,
				payload: allResponses
			});
		} catch (error) {
			dispatch({ type: FETCH_MAINTENANCE_TASK_SPARES_IN_PROPOSAL.FAILURE });
			console.error(error);
			throw error;
		}
	};
}

export const setSparePartMaintenanceGuideTimeModels = (sparePartProductNumber: number) => {
	return dispatch => {
		dispatch({
				type: SET_SPARE_PART_MAINTENANCE_GUIDE_TIME_IN_PROPOSAL,
				payload: sparePartProductNumber
			});
	};
};

export const setMaintenanceTaskSearchContext = (context: $Values<typeof MaintenanceTaskSearchContext>) => {
	return dispatch => {
		dispatch({
			type: SET_MAINTENANCE_TASK_SEARCH_CONTEXT,
			payload: context
		});
	};
};